<template>
<div>
  <b-row>
    <b-col md="6" v-if="nameMandatory">
      <field-text
        fieldId="firstName"
        placeholder="Nome"
        :state="isNameInvalid"
        v-model="person.name"
      />
    </b-col>
    <b-col md="6" v-if="nameMandatory">
      <field-text
        fieldId="lastName"
        placeholder="Cognome"
        :state="isSurnameInvalid"
        v-model="person.surname"
      />
    </b-col>
    <b-col>
      <field-text
        fieldId="personTaxCode"
        placeholder="Codice Fiscale"
        :state="isTaxCodeInvalid"
        v-model="person.taxCode"
        :errorMessage="taxCodeError"
      />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { checkTaxCode, isPresent } from '@/utils/validators';

const FieldText = () => import('@/components/fields/FieldText.vue');

export default {
  name: 'PersonData',
  components: { FieldText },
  props: {
    nameMandatory: Boolean,
    person: {
      // name: String,
      // surname: String,
      taxCode: String,
      // email: String,
    },
  },
  computed: {
    // emailError() {
    //   if (!isPresent(this.person.email)) {
    //     return 'Email obbligatoria';
    //   }
    //   if (!isEmail(this.person.email)) {
    //     return 'Email non valida';
    //   }
    //   return null;
    // },
    taxCodeError() {
      if (!isPresent(this.person.taxCode)) {
        return 'Codice fiscale obbligatorio';
      }
      if (!checkTaxCode(this.person.taxCode)) {
        return 'Codice fiscale non valido';
      }
      return null;
    },
    isNameInvalid() {
      return isPresent(this.person.name);
    },
    isSurnameInvalid() {
      return isPresent(this.person.surname);
    },
    // isEmailInvalid() {
    //   return false; // !isPresent(this.emailError);
    // },
    isTaxCodeInvalid() {
      return !isPresent(this.taxCodeError);
    },
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
